<template>
    <v-toolbar dense flat color="secondary" class="px-0 mb-3" height="36" dark>
        <v-toolbar-title class="subtitle-2 text-uppercase"
            >{{ capitalize(type) }} {{ title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
            icon
            text
            small
            class="mx-0"
            v-if="removeable"
            @click="$emit('remove')"
        >
            <v-icon right>cancel</v-icon>
        </v-btn>
    </v-toolbar>
</template>
<script>
export default {
    name: "control-tool-bar",
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "",
        },
        removeable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        capitalize(value) {
            return this.$_.startCase(value.toLowerCase());
        },
    },
};
</script>