var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "px-0 mb-3",
      attrs: { dense: "", flat: "", color: "secondary", height: "36", dark: "" }
    },
    [
      _c("v-toolbar-title", { staticClass: "subtitle-2 text-uppercase" }, [
        _vm._v(_vm._s(_vm.capitalize(_vm.type)) + " " + _vm._s(_vm.title))
      ]),
      _c("v-spacer"),
      _vm.removeable
        ? _c(
            "v-btn",
            {
              staticClass: "mx-0",
              attrs: { icon: "", text: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("remove")
                }
              }
            },
            [_c("v-icon", { attrs: { right: "" } }, [_vm._v("cancel")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }